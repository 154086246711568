<script setup lang="ts">
import { IonApp, IonRouterOutlet, actionSheetController } from '@ionic/vue';
import { URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import router from "@/router";
import { computed, watch } from "vue";
import { App } from '@capacitor/app';
import {
    ActionPerformed,
    PushNotifications,
} from '@capacitor/push-notifications';
import { store } from "@/store";
import StorageService from './services/storage.service';
import moment from 'moment';
import i18n from "@/i18n";

moment.locale(i18n.global.locale.value);
const user = computed(() => store.getters["profile/user"])

App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
    const slug = getSlug(event.url);

    if (slug) {
        router.push('/' + getSlug(slug));
    }
});

function getSlug(url: string) {
    [
        process.env.VUE_APP_BASE_URL + '/',
        process.env.VUE_APP_BASE_URL,
        'app.sportspoule.com/',
        'app.sportspoule.com',
        'http://',
        'https://',
        'sportspoule:///',
        'sportspoule://',
    ].forEach((item) => {
        url = url.replace(item, '');
    });

    return url;
}

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

watch(user, (newUser) => {
    if (newUser) {
        if (isPushNotificationsAvailable) {
            checkPermissions();
        }
    }
}, {
    immediate: true,
});

async function checkPermissions() {
    if ((await StorageService.get('pushNotifications')) != 'false') {
        if ((await PushNotifications.checkPermissions()).receive == 'granted') {
            initPushNotifications();
        } else {
            (await actionSheetController.create({
                header: 'Do you want to enable push notifications?',
                buttons: [
                    {
                        text: 'Yes',
                        handler: () => {
                            initPushNotifications();
                        },
                    },
                    {
                        text: 'No',
                        role: 'cancel',
                        handler: () => {
                            StorageService.set('pushNotifications', 'false');
                        },
                    },
                ],
            })).present();
        }
    }
}

function initPushNotifications() {
    PushNotifications.requestPermissions().then(({ receive }) => {
        if (receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
            PushNotifications.addListener('registration', (token: any) => {
                // send token to server
                if (!('fcmTokens' in user.value)) return;
                const tokens = (user.value.fcmTokens || '').split(',').filter((t: string) => t);
                if (!tokens.includes(token.value)) {
                    tokens.push(token.value);
                    user.value.fcmTokens = tokens.join(',');
                    store.dispatch('profile/update', user.value);
                }
            });

            PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
                if (action.notification.data.url) {
                    const url = action.notification.data.url;
                    const slug = getSlug(url);
                    if (slug) {
                        router.push('/' + getSlug(slug));
                    }
                }
            });
        } else {
            // Show some error
            alert('error');
        }
    });
}

</script>

<template>
    <ion-app>
        <ion-router-outlet />
    </ion-app>
</template>
