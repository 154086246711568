export function empty(value: any) {
    if (typeof value === 'function') {
        return false;
    }

    return typeof value === 'undefined'
        || value === null
        || (
            typeof value.length !== 'undefined'
            && value.length === 0
        )
        || value === 0;
}

// TODO : Copy content in typescript
// export function copyContentToClipboard(element: Node) {
//     const range = document.createRange();
//     range.selectNode(element);
//
//     window.getSelection().removeAllRanges();
//     window.getSelection().addRange(range);
//     document.execCommand('copy');
//     window.getSelection().removeAllRanges();
// }

export function ucf(str: string) {
    if (empty(str)) {
        return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function initials(fullName: string) {
    // Create a array from fullName
    const arrName = fullName.split(' ');

    // Select the first letter of the name
    const iniName = fullName.charAt(0);

    // Select the first letter of the lastname
    const iniLname = arrName[arrName.length - 1].charAt(0);

    // Return the initials
    return iniName.toUpperCase() + iniLname.toUpperCase();
}
//
// const dataURItoBlob = (dataURI: string) => {
//     const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
//         ? atob(dataURI.split(',')[1])
//         : unescape(dataURI.split(',')[1]);
//     const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
//     const max = bytes.length;
//     const ia = new Uint8Array(max);
//     for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
//     return new Blob([ia], {type: mime});
// };

// TODO : Resize images in typescript in stead of javascript
// export function resizeImage({file, maxSize}) {
//     const reader = new FileReader();
//     const image = new Image();
//     const canvas = document.createElement('canvas');
//
//     const resize = () => {
//         let {width, height} = image;
//
//         if (width > height) {
//             if (width > maxSize) {
//                 height *= maxSize / width;
//                 width = maxSize;
//             }
//         } else if (height > maxSize) {
//             width *= maxSize / height;
//             height = maxSize;
//         }
//
//         canvas.width = width;
//         canvas.height = height;
//         canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
//
//         const dataUrl = canvas.toDataURL('image/jpeg');
//
//         return dataURItoBlob(dataUrl);
//     };
//
//     return new Promise((ok, no) => {
//         if (!file.type.match(/image.*/)) {
//             no(new Error('Not an image'));
//             return;
//         }
//
//         reader.onload = (readerEvent) => {
//             image.onload = () => ok(resize());
//             // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
//             image.src = <string>readerEvent.target?.result;
//         };
//
//         reader.readAsDataURL(file);
//     });
// }
