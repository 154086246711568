<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar slot="bottom">
                <div id="open-group-navigation" :class="{ 'active': containsCurrentRoute('/groups') }"
                     class="tab"
                     @click="showGroupListPage">
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_208_416)">
                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM8 17.5C6.62 17.5 5.5 16.38 5.5 15C5.5 13.62 6.62 12.5 8 12.5C9.38 12.5 10.5 13.62 10.5 15C10.5 16.38 9.38 17.5 8 17.5ZM9.5 8C9.5 6.62 10.62 5.5 12 5.5C13.38 5.5 14.5 6.62 14.5 8C14.5 9.38 13.38 10.5 12 10.5C10.62 10.5 9.5 9.38 9.5 8ZM16 17.5C14.62 17.5 13.5 16.38 13.5 15C13.5 13.62 14.62 12.5 16 12.5C17.38 12.5 18.5 13.62 18.5 15C18.5 16.38 17.38 17.5 16 17.5Z"
                                  fill="url(#paint0_linear_208_416)" />
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_208_416" gradientUnits="userSpaceOnUse" x1="22" x2="-1.05035" y1="2" y2="17.4487">
                                <stop stop-color="#4C88FF" />
                                <stop offset="1" stop-color="#1A66FF" />
                            </linearGradient>
                            <clipPath id="clip0_208_416">
                                <rect fill="white" height="24" width="24" />
                            </clipPath>
                        </defs>
                    </svg>
                    {{ $t('group.index-tab') }}
                </div>
                <div :class="{ 'active': containsCurrentRoute('/profile') }"
                     class="tab"
                     @click="showAccountPage">
                    <div class="profile-image-wrapper">
                        <img v-if="profilePictureUrl"
                             :alt="profilePictureAlt"
                             :src="profilePictureUrl"
                             class="profile-image">
                        <div v-else class="initials">
                            {{ userInitials }}
                        </div>
                    </div>
                    {{ $t('profile.profile-tab') }}
                </div>
            </ion-tab-bar>
        </ion-tabs>

        <group-navigation-popover />

    </ion-page>
</template>

<script lang="ts" setup>
    import {
        IonPage,
        IonTabBar,
        IonTabs,
        IonRouterOutlet,
        onIonViewDidEnter
    } from '@ionic/vue';
    import { Haptics, ImpactStyle } from "@capacitor/haptics";
    import { useRoute } from "vue-router";
    import { computed, ref, watch } from "vue";
    import { store } from "@/store";
    import { empty } from "@/methods";
    import GroupNavigationPopover from "@/components/GroupNavigationPopover.vue";

    const route = useRoute();

    const user = computed(() => store.getters["profile/user"])
    const apiUrl = computed(() => process.env.VUE_APP_API_URL)
    const profilePictureUrl = ref(null);
    const profilePictureAlt = computed(() => 'Profile image of ' + user.value.name);
    const userInitials = computed(() => {
        if (!user?.value?.name) {
            return;
        }

        // Create an array from fullName
        const arrName = user.value.name.split(' ');

        // Select the first letter of the first name
        const firstName = user.value.name.charAt(0);

        // Select the first letter of the lastname
        const lastName = arrName[arrName.length - 1].charAt(0);

        // Return the initials
        return firstName.toUpperCase() + lastName.toUpperCase();
    });

    onIonViewDidEnter(async () => {
        await store.dispatch('profile/getProfile');

        //check if user has a profile picture and if its not null or empty
        if (!empty(user.value.profilePicture)) {
            profilePictureUrl.value = apiUrl.value + user.value.profilePicture
        }
    })

    watch(() => store.getters["profile/profile"], function () {
        if (!empty(user.value.profilePicture)) {
            profilePictureUrl.value = apiUrl.value + user.value.profilePicture
        } else {
            profilePictureUrl.value = null;
        }
    });

    function containsCurrentRoute(baseRoute: string) {
        return route.fullPath.includes(baseRoute);
    }

    function showAccountPage() {
        Haptics.impact({ style: ImpactStyle.Medium });

        window.location.href = '/profile';
    }

    function showGroupListPage() {
        Haptics.impact({ style: ImpactStyle.Medium });
    }
</script>

<style lang="scss">
    .tab {
        display         : flex;
        align-items     : center;
        flex-direction  : column;
        justify-content : center;
        width           : 50%;
        height          : 100%;
        font-size       : 10px;
        font-weight     : 600;
        font-style      : normal;
        line-height     : 1;
        text-align      : center;
        color           : hsl(0, 0%, 60%);
        gap             : 2px;

        &.active {
            color : hsl(0, 0%, 0%);

            svg {
                opacity : 1;
            }
        }

        svg {
            opacity : 0.6;
        }

        .profile-image-wrapper {
            position        : relative;
            display         : flex;
            align-items     : center;
            justify-content : center;
            overflow        : hidden;
            border          : 1px solid hsl(0, 0%, 90%);
            border-radius   : 12px;
            width           : 24px;
            height          : 24px;
            background      : var(--color-primary);

            .profile-image {
                z-index    : 2;
                width      : 100%;
                height     : 100%;
                object-fit : cover;
            }

            .initials {
                position        : absolute;
                display         : flex;
                align-items     : center;
                justify-content : center;
                width           : 100%;
                height          : 100%;
                font-size       : 10px;
                font-weight     : 600;
                line-height     : 1;
                color           : white;
            }
        }

    }

    .ion-page {
        // Media query from 1536 and higher
        @media (min-width : 1536px) {
            margin       : 0 auto;
            border-right : 1px solid rgba(0, 0, 0, 0.01);
            border-left  : 1px solid rgba(0, 0, 0, 0.01);
            max-width    : 800px;
        }
    }

</style>
