export default class User {
    id?: number;
    name?: string;
    profile_picture?: string;
    updated_at?: string;

    constructor(data: User) {
        Object.assign(this, data);
    }

    get profilePictureUrl() {
        return `${process.env.VUE_APP_API_URL}/${this.profile_picture}`;
    }
}
