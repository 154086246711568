export default {
    client: {
        id: process.env.VUE_APP_CLIENT_ID,
        secret: process.env.VUE_APP_CLIENT_SECRET,
    },
    options: {
        roles: [],
        redirects: {
            login: {
                name: 'groups.index',
            },
            failed: {
                name: 'auth.get-started',
            },
            success: {
                name: 'groups.index',
            },
        },
        token: {
            header: 'Authorization',
            type: 'Bearer',
            url: '/oauth/token',
        },
        storageNames: {
            token: 'sportspoule_token',
            refreshToken: 'sportspoule_refresh_token',
            expiresAt: 'sportspoule_expires_at',
        },
    },
};
