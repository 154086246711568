import GetStarted from "@/views/auth/AuthGetStarted.vue";
import guest from "@/router/utils/middleware/_guest";
import LogIn from "@/views/auth/AuthLogin.vue";
import ForgotPassword from "@/views/auth/AuthForgotPassword.vue";
import SetNewPassword from "@/views/auth/AuthSetNewPassword.vue";

export default [
    {
        name     : 'auth.get-started',
        path     : '/get-started',
        component: GetStarted,
        meta     : {
            middleware: guest
        }
    },
    {
        name     : 'auth.login',
        path     : '/login',
        component: LogIn,
        meta     : {
            middleware: guest
        }
    },
    {
        name     : 'auth.forgot-password',
        path     : '/wachtwoord-vergeten',
        component: ForgotPassword,
        meta     : {
            middleware: guest
        }
    },
    {
        name     : 'auth.set-new-password',
        path     : '/wachtwoord-opnieuw-instellen',
        component: SetNewPassword,
        meta     : {
            middleware: guest
        }
    },
    {
        name     : 'auth.register',
        path     : '/registreren',
        component: () => import('@/views/auth/AuthRegister.vue'),
        meta     : {
            middleware: guest
        }
    },
]
