import StorageService from '@/services/storage.service';
import AuthConfig from '../../../config/auth';
import Auth from "@/plugins/auth";

export default function auth(context: any) {
    if (context.to.name === 'auth.logout') {
        context.next(undefined);
        return;
    }
    Auth.getInstance(AuthConfig).check()
        .then(() => {
            context.next();
        })
        .catch(() => {
            StorageService.set('intended_url', context.to.path);
            context.next(AuthConfig.options.redirects.failed);
        });
}
