<template>
    <ion-page>
        <ion-content>
            <div class="desktop-panel">
                <img alt="Sportspoule patroon"
                     class="auth-header-image"
                     src="@/assets/images/auth-header-image.svg">
                <div class="auth-intro-header">
                    <h1>{{ $t('auth.forgot-password.new-password') }}</h1>
                </div>
                <form class="auth"
                      @submit.prevent="handleResetPassword">
                    <ion-input id="password"
                               v-model="form.password"
                               name="password"
                               :placeholder="$t('auth.forgot-password.new-password')"
                               required
                               type="password">
                    </ion-input>
                    <ion-input id="password"
                               v-model="form.password_confirmation"
                               name="password"
                               :placeholder="$t('auth.forgot-password.repeat-new-password')"
                               required
                               type="password">
                    </ion-input>
                    <ion-button class="btn"
                                expand="block"
                                shape="block"
                                type="submit">
                        {{ $t('auth.forgot-password.cta-set') }}
                    </ion-button>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" setup>
    import useAuth from "@/componentables/auth";
    import {
        IonPage,
        IonContent,
        IonInput,
    } from '@ionic/vue'
    import { useRouter } from 'vue-router';
    import { ref } from 'vue'

    const router = useRouter();
    const auth = useAuth();

    const form = ref({
        password: '',
        password_confirmation: '',
        token: router.currentRoute.value.query.token as string,
        email: router.currentRoute.value.query.email as string,
    })

    async function handleResetPassword() {
        await auth.resetPassword(form.value);

        router.push({ name: 'auth.login' });
    }
</script>

<style lang="scss" scoped>
    .auth-intro-header {
        position : relative;
        padding  : 168px 24px 48px 24px;

        h1 {
            font-size  : vw(32px);
            text-align : center;
        }
    }

    form {
        ion-input {
            margin-bottom : vw(8px);
        }

        .btn {
            margin-top    : vw(8px);
            margin-bottom : vw(48px);
        }

        .forgot-password {
            font-size   : 12px;
            font-weight : 600;
            line-height : 120%;
            text-align  : center;
            color       : rgba(0, 0, 0, 0.5);
        }
    }
</style>

