import nl from './nl.json';
import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import es from './es.json';
import pt from './pt.json';
import { createI18n } from 'vue-i18n';

export default createI18n({
    locale: navigator.language.substr(0, 2) || document.documentElement.lang || 'nl',
    fallbackLocale: 'en',
    legacy: false,
    messages: {
        nl,
        en,
        de,
        es,
        fr,
        pt
    },
});
