import { toastController } from "@ionic/vue";

export default class Toast {
    async open(message: string) {
        const toast = await toastController
            .create({
                message,
                duration: 2000
            })
        return toast.present();
    }
}
