<template>
    <div class="floating-back-button"
         @click="() => router.back()">
        <img src="@/assets/images/icons/arrow-back.svg">
    </div>
</template>

<script lang="ts" setup>
    import { useRouter } from 'vue-router';

    const router = useRouter();
</script>

<style lang="scss" scoped>
    .floating-back-button {
        display         : flex;
        align-items     : center;
        justify-content : center;
        border          : 1px solid rgba(0, 0, 0, 0.1);
        border-radius   : vw(20px);
        width           : vw(40px);
        height          : vw(40px);
        background      : rgba(255, 255, 255, 0.8);
        cursor          : pointer;
        transition      : border 0.2s ease-out;
        backdrop-filter : blur(8px);

        &:hover {
            border : 1px solid rgba(0, 0, 0, 0.15);

            img {
                transform : translateX(-2px);
            }
        }

        img {
            transition : transform 0.2s ease-out;
        }
    }
</style>
