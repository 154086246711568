import { Storage } from "@ionic/storage";

const StorageService = {
    async getStorage() {
        const ionStorage = new Storage();

        return await ionStorage.create();
    },
    async get(key: string) {
        return (await this.getStorage()).get(key);
    },
    async pull(key: string) {
        const value = await this.get(key);

        await this.remove(key);

        return value;
    },
    async set(key: string, value: string) {
        return (await this.getStorage()).set(key, value);
    },
    async remove(key: string) {
        return (await this.getStorage()).remove(key);
    },
};
export default StorageService;
