import {Commit, Dispatch} from "vuex";
import ApiService from "@/services/api.service";

const state = {
    profile: {
        user: {},
        group: {},
    }
};

const getters = {
    user: (state: { profile: { user: any; group: any } }) => state.profile.user,
    group: (state: { profile: { user: any; group: any } }) => state.profile.group,
    profile: (state: { profile: { user: any; group: any } }) => state.profile,
};

const mutations = {
    setUser(state: { profile: { user: any; group: any } }, user: any) {
        state.profile.user = user;
    },
    setProfile(state: { profile: { user: any; group: any } }, profile: any) {
        state.profile = profile;
    },
    clear(state: { profile: { user: any; group: any } }) {
        state.profile.user = {};
        state.profile.group = {};
    },
};

const actions = {
    async getProfile({commit}: { commit: Commit }) {
        const response = await ApiService.get('/profile');
        return commit('setProfile', response.data);
    },
    update({commit}: { commit: Commit }, data: { name: string; email: string; receiveSoccerReminderMails: boolean }) {
        return ApiService.post('/profile/edit', data)
            .then((response: any) => {
                commit('setUser', data);
                return response;
            });
    },
    updatePassword(context: any, data: { password: string; new_password: string }) {
        return ApiService.post('/profile/password/edit', data)
    },
    async clear({dispatch}: { dispatch: Dispatch }) {
        await dispatch('clear');
    },
    async delete({commit}: { commit: Commit }) {
        await commit('clear');
        return ApiService.delete('/profile')
            .then(() => {
                commit('clear');
            });
    }
};

export const profile = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

