import Auth from "@/plugins/auth";
import AuthConfig from "@/config/auth";

export default function guest(context: any) {
    const auth = Auth.getInstance(AuthConfig);
    const { route, router } = context;

    auth.check()
        .then(async () => {
            const url = await auth.getRedirectAfterLogin(route.query.redirect ? { path: route.query.redirect as string } : null);
            if (typeof url === 'string' && (url.includes('http://') || url.includes('https://'))) {
                window.location.href = url;
            } else {
                await router.push(url);
            }
        })
        .catch(() => {
            context.next();
        });
}
