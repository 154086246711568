import moment from 'moment';
import { empty } from '../methods';
import { Storage } from "@ionic/storage";
import ApiService from "@/services/api.service";
import StorageService from '@/services/storage.service';

export default class Auth {
    private static _instance: Auth;
    private options: Record<string, any>;
    private client: Record<string, any>;
    private storage: Storage|undefined;

    private constructor(configuration: Record<string, any> = {
        client : {
            id    : undefined,
            secret: undefined
        },
        options: {},
    }) {
        Auth._instance = this;

        this.options = {
            roles       : [],
            storageNames: {
                token       : 'token',
                refreshToken: 'refresh_token',
                expiresAt   : 'expires_at',
            },
            token       : {
                header: 'Authorization',
                type  : 'Bearer',
                url   : '/oauth/token',
            },
            redirects   : {
                login  : {
                    name: 'auth.login',
                },
                failed : {
                    name: 'auth.login',
                },
                success: {
                    name: 'groups.index',
                },
            },
            afterLogin() {
                return Promise.resolve();
            },

            ...configuration.options,
        };
        this.client = {
            client_id    : configuration.client.id,
            client_secret: configuration.client.secret,
            scope        : '*',
        };

        return this;
    }

    static getInstance(configuration: Record<string, any>) {
        if (!empty(Auth._instance)) {
            return Auth._instance;
        }

        return new Auth(configuration);
    }

    async isAuthenticated() {
        const token = await this.getToken();

        if (!empty(token)) {
            const expiresAt = await this.getExpiresAt();
            const now = new Date();

            return expiresAt > now;
        }

        return false;
    }

    async getToken() {
        return await this._get('token');
    }

    async getRefreshToken() {
        return await this._get('refreshToken');
    }

    async getExpiresAt() {
        const dateString = await this._get('expiresAt');
        if (dateString) {
            return new Date(dateString);
        }
        return new Date();
    }

    async check() {
        const isAuthenticated = await this.isAuthenticated();
        const refreshToken = await this.getRefreshToken();

        if (!isAuthenticated || empty(refreshToken)) {
            return Promise.reject();
        }

        const expiresAt = await this.getExpiresAt();
        expiresAt.setHours(expiresAt.getHours() - 2);

        const now = new Date();

        if (now < expiresAt) {
            return this.options.afterLogin();
        }

        const data = {
            ...this.client,
            grant_type   : 'refresh_token',
            refresh_token: refreshToken,
        };

        return new Promise((resolve, reject) => {
            ApiService.post(this.options.token.url, data, undefined)
                      .then((response: { data: { access_token: string; expires_in: string; refresh_token: string } }) => {
                          this._setToken(response.data);

                          this.options
                              .afterLogin()
                              .then(resolve)
                              .catch(reject);
                      })
                      .catch(() => {
                          this.logout();
                          reject();
                      });
        });
    }

    async login(user: { username: string; password: string }) {
        const formData = {
            ...this.client,
            grant_type: 'password',
            username  : user.username,
            password  : user.password,
        };

        const tokenResponse: { data: { access_token: string; expires_in: string; refresh_token: string } }
                  = await ApiService.post(this.options.token.url, formData, undefined);

        await this._setToken(tokenResponse.data);
    }

    async getRedirectAfterLogin(override: any = null) {
        return override || await StorageService.pull('intended_url') || this.options.redirects.success;
    }

    forgotPassword(email: any) {
        const formData = { email };

        return ApiService.post('/api/password/forgot', formData, undefined);
    }

    resetPassword(data: { token: string; email: string; password: string; password_confirmation: string }) {
        return ApiService.post('/api/password/reset', data, undefined);
    }

    async logout() {
        ApiService.removeHeader()
        await this._remove('token');
        await this._remove('refreshToken');
        await this._remove('expiresAt');
        window.location.href = "/get-started";
    }

    async register(data: any) {
        console.log('registering')
        const response = await ApiService.post('api/auth/register', {
            ...this.client,
            ...data,
        }, undefined);
        console.log('registered')
        await this._setToken(response.data);
        console.log('token set')
    }

    subscribe(data: any) {
        return ApiService.post('subscription',
            data)
    }

    async _setToken(data: { access_token: string; expires_in: string; refresh_token: string }) {
        await this._set('token', data.access_token);

        await this._set('refreshToken', data.refresh_token);
        const expiresAt = moment()
            .add(data.expires_in, 'seconds');

        await this._set('expiresAt', expiresAt.format('YYYY-MM-DDTHH:mm:ss'));

        await ApiService.setHeader();
    }

    async _get(what: string|number): Promise<Promise<any>|undefined> {
        if (!this.storage) {
            this.storage = await this.getStorage();
        }

        return await this.storage.get(
            this.options.storageNames[what]
        );
    }

    async _set(what: string, value: string) {
        if (!this.storage) {
            this.storage = await this.getStorage();
        }

        return this.storage ? this.storage.set(
            this.options.storageNames[what],
            value
        ) : false;

        return window.localStorage.setItem(
            this.options.storageNames[what],
            value,
        );
    }

    async _remove(what: string): Promise<Promise<any>|undefined> {
        if (!this.storage) {
            this.storage = await this.getStorage();
        }

        return await this.storage.remove(
            this.options.storageNames[what]
        );
    }

    async getStorage() {
        const ionStorage = new Storage();

        return await ionStorage.create();
    }
}

export const Authenticator = Auth;

export const VueAuthenticator = {
    install(vue: any, configuration: any) {
        const instance = Auth.getInstance(configuration);

        vue.provide('auth', configuration);

        vue.mixin({
            computed: {
                $auth() {
                    return instance
                }
            }
        });
    },
};
