import { store } from "@/store";
import User from "./user";

export default class Group {
    id?: number;
    avatar?: string | null;
    users?: User[];
    admins?: any[];
    canInviteMembers?: boolean;
    user_is_current_owner?: boolean;
    created_at?: string; // Assuming it's a date string, you might want to use a Date type if you're dealing with dates in your application
    deleted_at?: string; // It can be either null or a string
    name?: string;
    poule_id?: number;
    poule_type?: string;
    share_link?: string;
    updated_at?: string;
    everyone_can_invite?: boolean;
    everyone_can_rename?: boolean;
    tournament_has_started?: boolean;

    constructor(data: Group) {
        Object.assign(this, data);
        this.users = data.users?.map((user) => new User(user));
    }

    get avatarUrl() {
        return `${process.env.VUE_APP_API_URL}/storage/${this.avatar}`;
    }

    isAdmin(userId: number|null = null): boolean {
        if (!userId) {
            userId = store.getters['profile/user'].id;
        }

        return !!this.admins?.find((user) => user.id === userId);
    }

    canRename(userId: number|null = null): boolean {
        return this.everyone_can_rename || this.isAdmin(userId);
    }

    canInvite(userId: number|null = null): boolean {
        return this.everyone_can_invite || this.isAdmin(userId);
    }
}